import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Hi There 👋 I'm <br/><br/><b>POOJA RAOL</b>
        </p>
        <p
          className="App-link"
        >
          Website will be available soon.
        </p>
      </header>
    </div>
  );
}

export default App;
